<template>
  <section class="page-wrap">
    <!-- 表单搜索 start -->
    <section class="form">
      <div class="form-item">
        <el-select
          class="jn-input-style multiple-other"
          v-model="formData.buyer_id"
          collapse-tags
          multiple
          clearable
          placeholder="选择业务员"
        >
          <el-option
            v-for="item of buyerList"
            :key="item.id"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div class="form-item">
        <span class="label">周期：</span>
        <el-select
          class="jn-input-style"
          v-model="formData.order_cycle"
          clearable
          placeholder="选择周期"
        >
          <el-option
            v-for="item of orderCycleList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div class="form-item" v-if="formData.order_cycle">
        <el-date-picker
          v-if="formData.order_cycle === '1'"
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          align="right"
          v-model="dateTime"
          @change="(val) => changeCreateDate(val, 'daterange')"
        >
        </el-date-picker>
        <el-date-picker
          v-if="formData.order_cycle === '2'"
          v-model="weekTime"
          @change="(val) => changeCreateDate(val, 'week')"
          type="week"
          format="yyyy 第 WW 周"
          placeholder="选择周"
        >
        </el-date-picker>
        <el-date-picker
          v-if="formData.order_cycle === '3'"
          v-model="monthTime"
          @change="(val) => changeCreateDate(val, 'month')"
          type="month"
          placeholder="选择月"
        >
        </el-date-picker>
      </div>

      <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>
    </section>
    <!-- 表单搜索 end -->

    <!-- 内容区 start -->
    <section class="content">
      <div class="total">
        <div>下单总吨数： {{ tunnage_total || "-" }}吨</div>
        <div>下单总件数： {{ order_total || "-" }}件</div>
      </div>
      <!-- 列表 start -->
      <table-list :tableData="tableData" v-loading="loadingData"></table-list>
      <!-- 列表 end -->

      <!-- 分页 start -->
      <!-- <div class="pagination">
        <el-pagination
          type="primary"
          background
          @size-change="onHandleSizeChange"
          @current-change="onHandleCurrentChange"
          :current-page="formData.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="formData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div> -->
      <!-- 分页 end -->
    </section>
    <!-- 内容区 end -->
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import TableList from "./modules/table-list";
import {
  postBuyerList,
  postBuyerHotSaleSellStatistics,
  postAftersaleBuyerExport,
} from "@/api/statistic/index";
import { formatDate } from "@/utils//tools/date.js";

export default {
  name: "StatisticBuyer",
  components: {
    TableList,
  },
  data() {
    return {
      loadingData: false,
      disabledExport: false,
      dateTime: [], // 日期对象
      buyerList: [],
      weekTime: "",
      monthTime: "",
      formData: {
        buyer_id: "",
        order_cycle: "1",
        start_time: "", // 开始时间
        end_time: "", // 结束时间
        page: 1,
        // pageSize: 10,
      },
      formDataClone: {}, // 复制一份，用于初始化
      tunnage_total: "0.00",
      order_total: "0",
      tableData: [],
      total: 0,
      orderCycleList: [
        {
          id: "1",
          name: "日",
        },
        {
          id: "2",
          name: "周",
        },
        {
          id: "3",
          name: "月",
        },
      ],
    };
  },
  watch: {
    "formData.order_cycle"() {
      this.dateTime = [];
      this.weekTime = "";
      this.monthTime = "";
      this.onResetTime();
      this.dateTime = [this.formData.start_time, this.formData.end_time];
    },
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.onResetTime();
      this.dateTime = [this.formData.start_time, this.formData.end_time];
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxGetTableData();
      this.ajaxGetBuyerList();
    },
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.loadingData = true;
      const params = { ...this.formData };
      try {
        const { data } = await postBuyerHotSaleSellStatistics(params);
        const { tunnage_total, order_total, data: list } = data;
        this.tunnage_total = tunnage_total;
        this.order_total = order_total;
        this.tableData = list;
        // const { total, data: list } = data;
        // this.total = total;
        // this.tableData = list;postBuyerSellExport
      } catch (err) {
        console.log("ajax postBuyerHotSaleSellStatistics err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 获取业务员
     */
    async ajaxGetBuyerList() {
      const params = {
        page: 1,
        pageSize: 9999,
      };
      try {
        const { data } = await postBuyerList(params);
        console.log("🆒 ajaxGetBuyerList", data);
        this.buyerList = data;
      } catch (err) {
        console.log("ajax ajaxGetBuyerList err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 查询表单
     */
    onHandleSearch() {
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.onResetTime();
      this.dateTime = [this.formData.start_time, this.formData.end_time];
      this.ajaxGetTableData();
    },
    /**
     * 重置时间
     */
    onResetTime() {
      const day = 24 * 60 * 60 * 1000;
      const start = new Date().getTime() - day;
      this.formData.start_time = formatDate(start);
      this.formData.end_time = formatDate();
    },
    /**
     * 导出数据
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const params = {
          ...this.formData,
          token: sessionStorage.getItem("token"),
        };
        delete params.page;
        delete params.pageSize;
        await postAftersaleBuyerExport(params);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postAftersaleBuyerExport err", err);
      } finally {
        // this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
    /**
     * 日期发生了变化
     */
    changeCreateDate(data, type) {
      if (!data) {
        this.onResetTime();
        return;
      }
      let day = 24 * 60 * 60 * 1000;
      let start = "";
      let end = "";
      if (type === "daterange") {
        start = data?.[0] || "";
        end = data?.[1] || "";
      }
      if (type === "week") {
        let startNum = data.getTime() - day;
        let endNum = data.getTime() + day * 5;
        start = formatDate(startNum);
        end = formatDate(endNum);
      }
      if (type === "month") {
        let num = new Date(
          data.getYear() % 100,
          data.getMonth() + 1,
          0
        ).getDate();
        start = formatDate(data);
        let endNum = data.getTime() + day * (num - 1);
        end = formatDate(endNum);
      }
      // console.log("🆒 changeCreateDate", data, start, end);
      this.formData.start_time = start;
      this.formData.end_time = end;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 13px 15px;
    box-sizing: border-box;
    background: #ffffff;
    &-item {
      display: flex;
      .label {
        width: 90px;
        height: 36px;
        line-height: 36px;
        text-align: right;
      }
      .el-input {
        flex: 1;
      }
      &.btn-wrap {
        position: relative;
        top: -2.5px;
        left: 20px;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    .total {
      display: flex;
      gap: 20px;
      padding-bottom: 20px;
      padding-left: 50px;
      background: #fff;
    }
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
